<template>
    <div>
        <el-input type="textarea" v-model="msg"></el-input>
        <el-button @click="handleSend">Send</el-button>
    </div>
</template>

<script>
import {HubConnectionBuilder} from '@aspnet/signalr';

export default {
    name: 'TestIndex',
    data() {
        return {
            connection: null,
            count: 1,
            msg: JSON.stringify({
                type: 2,
                data: {
                    scheduleType: 'fly',
                    id: 1541654564564,
                    scheduleNotifyType: 'RefreshUserInfo',
                    staffIds: ['7dbb0433-ec68-49f6-ba57-295ceaa80235'],
                    startTime: '2019-06-18T06:30:00',
                    endTime: '2019-06-18T13:50:00'
                }
            })
        };
    },
    created() {
        this.connection = new HubConnectionBuilder().withUrl('/Notification').build();
    },
    mounted() {
        this.connection.start();
        this.connection.on('ReceiveMessage', message => {
            this.$message('收到新消息用户[user]:' + JSON.stringify(message));
        });
    },
    methods: {
        handleSend() {
            this.connection.send('SendMessageNew', JSON.parse(this.msg));
        }
    }
};
</script>
